import clsx from "clsx";
import { useLocation } from "react-router";
import { asLink } from "~/sanity/sanity-helpers";
import { Link } from "~/components/ui/link";
import { Paragraph } from "~/components/ui/typography";
import type {
	NavigationDocument,
	NavItem,
	NavLinkGroup,
	Link as NavLink,
	NavSubMenu,
} from "~/types/sanity-schema";
import * as NavigationMenu from "@radix-ui/react-navigation-menu";
import { Icon } from "~/components/ui/icons";
import { groupBy } from "lodash-es";
import { SubNavLinkGroup } from "./subnav-link-group";
import { spTrackWebInteraction } from "~/utils/tracking";

type MegaNavProps = {
	navigationData: NavigationDocument;
};

export function MegaNav({ navigationData }: MegaNavProps) {
	if (!navigationData) return null;

	return (
		<NavigationMenu.Root delayDuration={300}>
			<>
				<NavigationMenu.List className="relative flex h-nav-secondary items-center">
					{navigationData.items?.map((navItem, index) => (
						<NavItem key={index} navItem={navItem} />
					))}
				</NavigationMenu.List>
				<div className="absolute left-[180px] top-[calc(100%-9px)] z-50 flex justify-start">
					<NavigationMenu.Viewport
						className={clsx(
							"border-stroke bg-primary relative overflow-hidden rounded-lg border transition-[width,height]",
							"data-[state=open]:animate-fadeInScale",
							"data-[state=closed]:animate-fadeOutScale"
						)}
						style={{
							height: "var(--radix-navigation-menu-viewport-height)",
							width: "var(--radix-navigation-menu-viewport-width)",
						}}
					/>
				</div>
			</>
		</NavigationMenu.Root>
	);
}

type NavItemProps = {
	navItem: NavLink | NavSubMenu;
};

function NavItem({ navItem }: NavItemProps) {
	const location = useLocation();
	const { title, _type } = navItem;
	const navLinkGroups =
		_type === "navSubmenu" ? (navItem as NavSubMenu).navLinkGroups : undefined;
	const locale = "locale" in navItem ? navItem.locale : null;
	const pageLocale = "pageLocale" in navItem ? navItem.pageLocale : undefined;

	if (!title) return null;

	if (_type === "link") {
		const url = asLink(navItem);
		if (!url) return null;

		const isActive = url === location.pathname;
		const isLinkToEnglishPage = locale === "en" && pageLocale !== "en";

		return (
			<NavigationMenu.Item>
				<Link
					to={url}
					trackingText={title}
					trackingPosition="menu"
					className={clsx("text-primary relative px-3 py-4", {
						"hover-underline-expand": !isActive,
						"underline decoration-theme-primary decoration-1 underline-offset-8":
							isActive,
					})}
					title={isLinkToEnglishPage ? "In English" : ""}
				>
					<Paragraph
						as="span"
						size="body-small"
						fontWeight="font-medium"
						color="current"
						data-label="link-title"
					>
						{title}
					</Paragraph>
				</Link>
			</NavigationMenu.Item>
		);
	}

	if (_type === "navSubmenu") {
		return navLinkGroups ? (
			<DropdownMenu buttonName={title} navLinkGroups={navLinkGroups} />
		) : null;
	}
}

type DropdownMenuProps = {
	buttonName: string;
	navLinkGroups: Array<NavLinkGroup>;
};

function DropdownMenu({ buttonName, navLinkGroups }: DropdownMenuProps) {
	if (!navLinkGroups) return null;

	const linksGroupedByColumn = groupBy(navLinkGroups, "group");
	// Get columns, excluding "horizontal-row"
	const columns = Object.keys(linksGroupedByColumn).filter(
		(col) => col !== "horizontal-row"
	);

	// Determine if "horizontal-row" exists
	const hasHorizontalRow = "horizontal-row" in linksGroupedByColumn;

	// Check if the first column (column-1) has a gradient background
	const firstColumnHasGradientBg = linksGroupedByColumn["column-1"]?.some(
		(group) => group.gradientBackground
	);

	// Determine column span for the horizontal row
	const horizontalRowClass = firstColumnHasGradientBg
		? "col-span-3 col-start-2"
		: "col-span-4";

	const columnsWithGradient = Object.fromEntries(
		Object.entries(linksGroupedByColumn).map(([column, groups]) => [
			column,
			groups.some((group) => group.gradientBackground),
		])
	);

	return (
		<NavigationMenu.Item>
			<NavigationMenu.Trigger
				className="hover-underline-expand group relative flex items-center p-4"
				onClick={() => {
					spTrackWebInteraction({
						object: "menu",
						action: "dropdown-trigger",
						value: `${buttonName}`,
					});
				}}
			>
				<Paragraph
					as="span"
					size="button-small"
					color="current"
					data-label="link-title"
				>
					{buttonName}
				</Paragraph>
				<Icon
					name="chevron-down"
					color="arrow"
					className={clsx(
						"ml-3 transition-transform",
						"group-data-[state=open]:-rotate-180"
					)}
				/>
			</NavigationMenu.Trigger>
			<NavigationMenu.Content
				className={clsx(
					"bg-primary absolute left-0 max-h-mega-nav overflow-y-auto",
					"data-[motion=from-start]:animate-enterFromLeft",
					"data-[motion=from-end]:animate-enterFromRight",
					"data-[motion=to-start]:animate-exitToLeft",
					"data-[motion=to-end]:animate-exitToRight"
				)}
			>
				<div
					className="grid p-3 [grid-template-columns:repeat(var(--col-count),minmax(256px,1fr))] 2xl:[grid-template-columns:repeat(var(--col-count),minmax(300px,1fr))]"
					style={{ "--col-count": columns.length } as React.CSSProperties}
				>
					{columns.sort().map((column, index) => (
						<div
							key={column}
							className={clsx("flex w-[256px] flex-col p-3 2xl:w-[300px]", {
								"bg-gradient-to-b from-[rgb(var(--color-secondary-5))] to-white rounded-lg":
									columnsWithGradient[column],
								"min-h-full row-span-2": index === 0 && hasHorizontalRow, // Ensure first column covers both rows
							})}
						>
							{linksGroupedByColumn[column].map((group, index) =>
								group.items ? (
									<SubNavLinkGroup
										key={index}
										headerVariant={group.headerVariant}
										headerLink={group.headerLink}
										headerImage={group.headerImage}
										headerTitle={
											group.headerVariant === "withLink"
												? group.headerLink?.title
												: group.title
										}
										layout="vertical"
										gradientBg={columnsWithGradient[column]}
										items={group.items}
									/>
								) : null
							)}
						</div>
					))}

					{hasHorizontalRow && (
						<div className={clsx("p-3", horizontalRowClass)}>
							{linksGroupedByColumn["horizontal-row"].map((group, index) => (
								<SubNavLinkGroup
									key={index}
									headerVariant={group.headerVariant}
									headerLink={group.headerLink}
									headerImage={group.headerImage}
									headerTitle={
										group.headerVariant === "withLink"
											? group.headerLink?.title
											: group.title
									}
									layout="horizontal"
									items={group.items}
								/>
							))}
						</div>
					)}
				</div>
			</NavigationMenu.Content>
		</NavigationMenu.Item>
	);
}
