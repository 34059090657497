import clsx from "clsx";
import { Image } from "~/components/ui/image";
import { Container } from "~/components/ui/container";
import { Paragraph } from "~/components/ui/typography";
import { Iframe } from "~/components/iframe";
import { Link } from "~/components/ui/link";
import { asLink } from "~/sanity/sanity-helpers";
import { useLocale, useSharedContent } from "~/hooks/localization";
import type { FooterQuery } from "~/types/queries";
import { CookieSettingLink } from "./cookie-settings-link";
import { LanguageSelector } from "~/components/language-selector";
import iconGithub from "~/images/icon-github.svg";
import iconFacebook from "~/images/icon-facebook.svg";
import iconLinkedin from "~/images/icon-linkedin.svg";
import iconTwitter from "~/images/icon-twitter.svg";
import iconYoutube from "~/images/icon-youtube.svg";
import { usePageAvailableLocales } from "~/hooks/common";
import AivenLogo from "~/images/logo-aiven-white.svg";

enum FooterIDs {
	CONTACTS = "contacts",
	TERMS_POLICIES = "terms-policies",
}

export type SocialLinks = {
	icon: string;
	url: string;
	title: string;
};

interface Props {
	footerLinks: FooterQuery;
}

export function Footer({ footerLinks }: Props) {
	const locale = useLocale();
	const { t } = useSharedContent();
	const availableLocales = usePageAvailableLocales();
	const shouldShowLanguageSelector = availableLocales.length > 0;

	const socialLinks: SocialLinks[] = [
		{
			icon: iconGithub,
			url: "https://github.com/aiven",
			title: "Github",
		},
		{
			icon: iconFacebook,
			url: "https://www.facebook.com/aivencloud",
			title: "Facebook",
		},
		{
			icon: iconLinkedin,
			url: "https://www.linkedin.com/company/aiven/",
			title: "LinkedIn",
		},
		{
			icon: iconTwitter,
			url: "https://twitter.com/aiven_io",
			title: "Twitter",
		},
		{
			icon: iconYoutube,
			url: "https://www.youtube.com/channel/UC4cZf5RyAD5EL0fQf5u8c1g",
			title: "Youtube",
		},
	];

	const productRelatedLinks = footerLinks.filter(
		(item) =>
			item.id !== FooterIDs.CONTACTS && item.id !== FooterIDs.TERMS_POLICIES
	);
	const termsPoliciesLinks = footerLinks.find(
		(item) => item.id === FooterIDs.TERMS_POLICIES
	);

	const doNotShareLink = "/privacy/california?m=opt-out";
	const termsPolicieslLinksFilteredCaliforniaNotice =
		termsPoliciesLinks?.links?.filter(
			(link) => !asLink(link)?.includes(doNotShareLink)
		);
	const californiaNoticeLink = termsPoliciesLinks?.links?.find((link) =>
		asLink(link)?.includes(doNotShareLink)
	);

	return (
		<footer role="contentinfo" className="dark bg-grey-100">
			<Container>
				<div className="mb-layout5 flex flex-col justify-between gap-6 lg:flex-row">
					<div className="flex flex-1 flex-col flex-wrap gap-6 md:flex-row">
						<div className="basis-full border-b border-grey-40 pb-6 xl:mr-7 xl:shrink xl:grow 2xl:basis-1/6 2xl:border-none 2xl:pb-0">
							<Image
								width="140px"
								height="47px"
								src={AivenLogo}
								alt="Aiven Logo at footer"
							/>
							<Paragraph size="body-small" className="pb-6">
								{t(
									"footerAboutAivenText",
									"Aiven is a data and AI platform that combines open-choice services to rapidly stream, store and serve data across major cloud providers — simply and securely."
								)}
							</Paragraph>
							{locale === "en" ? (
								<div className="xl:w-[90%]">
									<Iframe
										title="Newsletter subscription footer"
										src="https://go.aiven.io/l/890043/2023-10-20/t7d6j"
										minHeight={140}
										isPardotForm={true}
										pardotFormType="newsletter-footer"
									/>
								</div>
							) : null}
							<ul className="mt-3 flex flex-row gap-5">
								{socialLinks.map((item) => (
									<li key={item.icon}>
										<Link
											title={item.title}
											to={item.url}
											trackingText={item.title}
											trackingPosition="footer"
											aria-label={`Reach out on ${item.title}`}
										>
											<Image src={item.icon} alt={item.title} />
										</Link>
									</li>
								))}
							</ul>
						</div>
						{productRelatedLinks.map((item) => (
							<div key={item.title} className="md:flex-[0_0_48%] xl:flex-1">
								<Paragraph size="body-default">{item.title}</Paragraph>
								<ul>
									{item.links?.map((link) => {
										const isLinkToEnglishPage =
											link.locale === "en" && link.pageLocale !== "en";
										return (
											<Paragraph
												size="body-xsmall"
												as="li"
												key={link.title}
												className="mt-2"
											>
												<Link
													to={asLink(link) || ""}
													trackingPosition="footer"
													className="whitespace-nowrap text-grey-40 underline-offset-4 hover:underline"
													title={isLinkToEnglishPage ? "English" : ""}
												>
													{link.title}
												</Link>
											</Paragraph>
										);
									})}
								</ul>
							</div>
						))}
					</div>
				</div>
				<hr className="mt-9 text-grey-40" />
				<div className="my-6 flex flex-1 flex-col flex-wrap gap-6 lg:flex-row">
					<div className="lg:flex-1">
						{shouldShowLanguageSelector ? <LanguageSelector /> : null}
					</div>
					<div className="items-end lg:justify-end">
						<ul className="flex flex-row flex-wrap justify-end">
							{termsPolicieslLinksFilteredCaliforniaNotice?.map(
								(link, idx, arr) => {
									const isLinkToEnglishPage =
										link.locale === "en" && link.pageLocale !== "en";
									return (
										<Paragraph
											size="body-xsmall"
											as="li"
											key={link.title}
											className="mt-2"
										>
											<Link
												to={asLink(link) || ""}
												trackingPosition="footer"
												className={clsx(
													"whitespace-nowrap px-3 underline-offset-4 hover:underline",
													idx < arr.length - 1
														? "border-r border-r-grey-40"
														: ""
												)}
												title={isLinkToEnglishPage ? "English" : ""}
											>
												{link.title}
											</Link>
										</Paragraph>
									);
								}
							)}
						</ul>
						<ul className="flex flex-row flex-wrap justify-end">
							{californiaNoticeLink ? (
								<Paragraph
									size="body-xsmall"
									as="li"
									key={californiaNoticeLink?.title}
									className="mt-2 border-r border-r-grey-40"
								>
									<Link
										to={asLink(californiaNoticeLink) || ""}
										trackingPosition="footer"
										className={clsx(
											"whitespace-nowrap px-3 underline-offset-4 hover:underline"
										)}
									>
										{californiaNoticeLink?.title}
									</Link>
								</Paragraph>
							) : null}
							<Paragraph as="li" size="body-xsmall" className="mt-2">
								<CookieSettingLink />
							</Paragraph>
						</ul>
					</div>
				</div>
				<Paragraph size="body-xsmall" className="text-center !text-grey-40">
					{t("footerText")}
				</Paragraph>
			</Container>
		</footer>
	);
}

interface SimpleFooterProps {
	showCookieSetting?: boolean;
}

export function SimpleFooter({ showCookieSetting = true }: SimpleFooterProps) {
	const { t } = useSharedContent();

	return (
		<footer role="contentinfo" className="dark bg-grey-100 text-center">
			<Container>
				<Paragraph size="body-xsmall" className="!text-grey-40">
					{t(
						"footerText",
						"Apache, Apache Kafka, Kafka, Apache Flink, Flink, Apache Cassandra, and Cassandra are either registered trademarks or trademarks of the Apache Software Foundation in the United States and/or other countries. ClickHouse is a registered trademark of ClickHouse, Inc. https://clickhouse.com. M3, M3 Aggregator, OpenSearch, AlloyDB Omni, PostgreSQL, MySQL, InfluxDB, Grafana, Dragonfly, Valkey, Thanos, Terraform, and Kubernetes are trademarks and property of their respective owners. *Redis is a registered trademark of Redis Ltd. and the Redis box logo is a mark of Redis Ltd. Any rights therein are reserved to Redis Ltd. Any use by Aiven is for referential purposes only and does not indicate any sponsorship, endorsement or affiliation between Redis and Aiven. All product and service names used in this website are for identification purposes only and do not imply endorsement."
					)}
				</Paragraph>
				{showCookieSetting && <CookieSettingLink />}
			</Container>
		</footer>
	);
}
