import { useRef, useEffect, useState } from "react";
import clsx from "clsx";
import TagManager from "react-gtm-module";
import {
	Links,
	Meta,
	Outlet,
	Scripts,
	ScrollRestoration,
	useLocation,
	useRevalidator,
	useNavigation,
	useRouteError,
	isRouteErrorResponse,
} from "react-router";
import { ErrorPage } from "~/components/errors";
import globalStyles from "./styles/app.css?url";
import tailwindStyles from "./styles/tw.css?url";
import vendorStyles from "./styles/vendors.css?url";
import { Footer, SimpleFooter } from "./components/footer/footer";
import { Navbar, SimpleNavbar } from "./components/navbar";
import { getSocialMetas, defaultMetaData } from "~/utils/seo";
import {
	getDomainUrl,
	isDevelopment,
	removeLangCode,
	removeTrailingSlash,
	getUrl,
	getOrigin,
} from "~/utils/misc";
import { getLanguage, validateLanguage, type Language } from "~/utils/language";
import { getSharedContent } from "./queries/localization.server";
import { usePageLoaderData } from "./hooks/common";
import { getFooter } from "./queries/page.server";
import { getPreview } from "~/sanity/sanity.server";
import { getNavigation } from "~/queries/navigation.server";
import { NavProvider } from "~/utils/nav-provider";
import {
	getSnowplow,
	getCookieConsent,
	getQualified,
	usePageTracking,
	getAccountEngagement,
	get6Sense,
} from "~/utils/tracking";
import { ENV, getEnv } from "./utils/env.server";
import { captureException } from "@sentry/react";
import { PageBanner, PostHogBanner } from "./components/PageAddOns";
import {
	ExperimentProvider,
	getPosthogBootstrapData,
	getPageTrackingExperimentData,
} from "~/utils/experiment";
import type { PosthogBootstrapDataType } from "~/utils/experiment";
import type { NavigationDocument } from "./types/sanity-schema";
import { ProgressBarLoop } from "./components/ui/progress-bar";
import { ButtonLink } from "./components/ui/button";
import { monacoVsPath } from "~/utils/monaco-editor/loader-setup";
import type { FooterQuery } from "~/types/queries";
import type { Route } from "./+types/root";
import { SerializeFrom } from "./utils/types";

const navigationId = "main-menu";

function RevalidateOnNavigation() {
	const { revalidate } = useRevalidator();
	const location = useLocation();
	const previousLocale = useRef<string>("en");

	useEffect(() => {
		const pathnameParts = location.pathname.split("/");
		const localeFromPathname = pathnameParts[1];
		const currentLocale = validateLanguage(localeFromPathname)
			? localeFromPathname
			: "en";

		if (currentLocale !== previousLocale.current) {
			previousLocale.current = currentLocale;
			revalidate();
		}
	}, [location, revalidate]);

	return null;
}

export type RootLoaderData = {
	requestInfo: {
		origin: string;
		path: string;
	};
	locale: Language;
	footerLinks: FooterQuery;
	sharedContent: Record<string, Record<string, string>>;
	navigationData: NavigationDocument;
	isPreview: boolean;
	ENV: ENV;
	bootstrapData?: Record<string, string | boolean>;
	iframeResizerLicense?: string;
};

export const loader = async ({ request }: Route.LoaderArgs) => {
	const locale = getLanguage(request);
	const { isPreview } = getPreview(request);

	const [footerLinks, navigationData, sharedContent] = await Promise.all([
		getFooter(locale),
		getNavigation(locale, navigationId),
		getSharedContent(locale),
	]);

	const bootstrapData = await getPosthogBootstrapData(request);

	const iframeResizerLicense = process.env.IFRAME_RESIZER_LICENSE;

	return {
		requestInfo: {
			origin: getDomainUrl(request),
			path: new URL(request.url).pathname,
		},
		locale,
		footerLinks,
		sharedContent,
		navigationData,
		isPreview,
		ENV: getEnv(isPreview),
		bootstrapData,
		iframeResizerLicense,
	};
};

export const meta: Route.MetaFunction = ({ data }) => {
	const requestInfo = data?.requestInfo;

	return getSocialMetas({
		url: getUrl(requestInfo),
		origin: getOrigin(requestInfo),
		title: defaultMetaData.title,
	});
};

export const links: Route.LinksFunction = () => {
	return [
		{ rel: "preload", href: globalStyles, as: "style" },
		{ rel: "preload", href: tailwindStyles, as: "style" },
		{ rel: "preload", href: vendorStyles, as: "style" },
		{
			rel: "preload",
			href: "https://cdn.jsdelivr.net/npm/katex@0.16.8/dist/katex.min.css",
			as: "style",
		},
		{
			rel: "preload",
			as: "font",
			href: "/fonts/poppins-v20-latin-600.woff2",
			type: "font/woff2",
			crossOrigin: "anonymous",
		},
		{
			rel: "preload",
			as: "font",
			href: "/fonts/inter-v12-latin-regular.woff2",
			type: "font/woff2",
			crossOrigin: "anonymous",
		},
		{
			rel: "preload",
			as: "font",
			href: "/fonts/inter-v12-latin-500.woff2",
			type: "font/woff2",
			crossOrigin: "anonymous",
		},
		{ rel: "stylesheet", href: globalStyles },
		{ rel: "stylesheet", href: vendorStyles },
		{ rel: "stylesheet", href: tailwindStyles },
		{
			rel: "apple-touch-icon",
			sizes: "180x180",
			href: "/favicons/apple-touch-icon.png",
		},
		{
			rel: "icon",
			type: "image/png",
			sizes: "32x32",
			href: "/favicons/favicon-32x32.png",
		},
		{
			rel: "icon",
			type: "image/png",
			sizes: "16x16",
			href: "/favicons/favicon-16x16.png",
		},
		{
			rel: "icon",
			type: "image/png",
			sizes: "64x64",
			href: "/favicons/favicon-64x64.png",
		},
		{ rel: "icon", href: "/favicon.ico" },
		{ rel: "manifest", href: "/site.webmanifest" },
	];
};

const monacoEditorPreloadingScripts = [
	{
		rel: "preload",
		as: "script",
		href: `${monacoVsPath}/loader.js`,
	},
	{
		rel: "preload",
		as: "script",
		href: `${monacoVsPath}/editor/editor.main.js`,
	},
	{
		rel: "preload",
		as: "script",
		href: `${monacoVsPath}/basic-languages/sql/sql.js`,
	},
	{
		rel: "prefetch",
		as: "script",
		href: `${monacoVsPath}/base/worker/workerMain.js`,
	},
];

export const renderHrefLang = (
	origin: string,
	path: string,
	locales: Language[] = []
) => {
	const originWithoutTrailingSlash = removeTrailingSlash(origin);
	return (
		<>
			{locales.map((locale) => (
				<link
					key={locale}
					rel="alternate"
					href={`${originWithoutTrailingSlash}/${locale}${path}`}
					hrefLang={locale}
				/>
			))}
			<link
				rel="alternate"
				href={`${originWithoutTrailingSlash}${path}`}
				hrefLang="en"
			/>
			<link
				rel="alternate"
				href={`${originWithoutTrailingSlash}${path}`}
				hrefLang="x-default"
			/>
		</>
	);
};

function SecondaryNav(
	locale: Language,
	secondaryNavigation: NavigationDocument
) {
	return <Navbar navigationData={secondaryNavigation} lang={locale} />;
}

function App({ loaderData }: { loaderData: SerializeFrom<RootLoaderData> }) {
	const {
		locale,
		requestInfo: { origin, path },
		footerLinks,
		isPreview,
		navigationData,
		ENV,
		bootstrapData,
	} = loaderData;

	const navRef = useRef<HTMLDivElement>(null);
	const [navHeight, setNavHeight] = useState(0);

	const pageData = usePageLoaderData();

	const navigation = useNavigation();

	const isNavigating = navigation.state === "loading";

	const location = useLocation();
	const canonicalUrl =
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		(pageData && (pageData.data as any)?.seo?.canonicalUrl) || path;

	const pathWithoutLangCode = removeLangCode(path);

	const shouldRenderExternalScripts =
		!ENV?.CI && !isDevelopment() && !isPreview;

	const isSimplifiedNavigation = pageData?.data?.isSimplifiedNav;

	// initialize GTM on an effect so it runs after hydration
	useEffect(() => {
		if (shouldRenderExternalScripts) {
			const GTM_ID = "GTM-KVSR9J7";

			TagManager.initialize({
				gtmId: GTM_ID,
			});
		}
	}, [shouldRenderExternalScripts]);

	useEffect(() => {
		if (navRef.current) {
			setNavHeight(navRef.current.offsetHeight);
		}
	}, [navRef]);

	const hasTopBanner = Boolean(pageData?.data?.infoBanner);

	const isHomepage = location.pathname === "/";
	const isToolsPage = location.pathname.startsWith("/tools");

	const experiment = getPageTrackingExperimentData({
		pageData,
		posthogBootstrapData: bootstrapData as PosthogBootstrapDataType,
		path: location.pathname,
	});

	usePageTracking(
		experiment
			? {
					locale: locale,
					featureFlagName: experiment.featureFlagName,
					experimentVariant:
						typeof experiment.experimentVariant === "string"
							? experiment.experimentVariant
							: "",
				}
			: {
					locale: locale,
				}
	);

	return (
		<html lang={locale} className={clsx({ "lg:scroll-smooth": !isNavigating })}>
			<head>
				<meta name="robots" content="noindex" />
				<meta charSet="utf-8" />
				<meta name="viewport" content="width=device-width,initial-scale=1" />
				{/* Zendesk Federated Search for all aiven.io pages */}
				<meta name="zd-site-verification" content="40tpboazshgt0ngbgwpr2" />
				{/* Only in homepage for Zendesk site verification for Docs page crawler */}
				{isHomepage ? (
					<>
						<meta
							name="zd-site-verification"
							content="1tsz6w2s2we597lbplg9ou"
						/>
						{/* Testing in staging for AI and chatbot */}
						<meta
							name="zd-site-verification"
							content="2d2wikxl428u5v2tmxmu3j"
						/>
					</>
				) : null}
				<Meta />
				{renderHrefLang(
					origin,
					pathWithoutLangCode,
					pageData?.availableLocales
				)}
				<link
					rel="canonical"
					href={`${removeTrailingSlash(origin)}${canonicalUrl}`}
				/>
				<Links />
				{shouldRenderExternalScripts ? (
					<>
						<script async dangerouslySetInnerHTML={{ __html: getSnowplow }} />
						<script
							defer
							src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
							data-domain-script="0623fbc6-a463-4822-a7a4-fdb5afcc3afb"
							data-document-language="true"
						/>
						<script
							async
							dangerouslySetInnerHTML={{
								__html: getAccountEngagement,
							}}
						/>
						<script
							async
							dangerouslySetInnerHTML={{
								__html: getCookieConsent,
							}}
						/>
						<script
							async
							dangerouslySetInnerHTML={{
								__html: getQualified,
							}}
						/>
						<script
							defer
							src="https://js.qualified.com/qualified.js?token=rSyShDaLFhNDinw3"
						></script>
						<script
							async
							dangerouslySetInnerHTML={{
								__html: get6Sense,
							}}
						/>
					</>
				) : null}
				{isToolsPage && (
					<>
						{monacoEditorPreloadingScripts.map((script, index) => {
							return (
								<link
									rel={script.rel}
									href={script.href}
									as={script.as}
									key={index}
								/>
							);
						})}
					</>
				)}
			</head>
			<body className="text-primary bg-primary font-body">
				{isDevelopment() && !isPreview ? (
					<div className="text-primary fixed bottom-3 right-3 z-50 bg-green-0 p-1 text-center opacity-60">
						Development Mode
					</div>
				) : null}
				<RevalidateOnNavigation />
				{hasTopBanner ? (
					<PageBanner data={pageData?.data} />
				) : (
					<PostHogBanner />
				)}
				{isNavigating ? <ProgressBarLoop isNavigating={isNavigating} /> : null}
				<div className="outline-0" id="a11y-nav" tabIndex={0}>
					<ButtonLink
						to="#main"
						variant="primary"
						className="absolute z-40 ml-3 -translate-y-layout7 transition duration-300 ease-in-out focus:translate-y-layout6"
					>
						Skip to content
					</ButtonLink>
				</div>
				<div className="sticky top-0 z-40" id="main-nav" ref={navRef}>
					{isSimplifiedNavigation ? (
						<SimpleNavbar />
					) : (
						<Navbar navigationData={navigationData} lang={locale} />
					)}

					{pageData?.data?.secondaryNavigation
						? SecondaryNav(locale, pageData.data.secondaryNavigation)
						: null}
				</div>

				<NavProvider navHeight={navHeight}>
					<ExperimentProvider
						posthogBootstrapData={bootstrapData as PosthogBootstrapDataType}
					>
						<main tabIndex={-1} id="main" role="main">
							<Outlet />
							<script
								dangerouslySetInnerHTML={{
									__html: `window.ENV = ${JSON.stringify(ENV)};`,
								}}
							/>
						</main>
					</ExperimentProvider>
				</NavProvider>
				{isSimplifiedNavigation ? (
					<SimpleFooter />
				) : (
					<Footer footerLinks={footerLinks} />
				)}
				<ScrollRestoration />
				<Scripts />
			</body>
		</html>
	);
}

export default App;

// Global fallback for any unhandled errors that are not caught by route-specific boundaries
export function ErrorBoundary() {
	const error = useRouteError();
	const isResponse = isRouteErrorResponse(error);

	if (typeof document !== "undefined") {
		console.error(error);
	}

	useEffect(() => {
		if (isResponse) return;

		captureException(error);
	}, [error, isResponse]);

	return (
		<html>
			<head>
				<title>
					We&apos;re currently experiencing some technical difficulties
				</title>
				<Links />
			</head>
			<body className="text-primary bg-primary">
				<SimpleNavbar />
				<ErrorPage
					title="We're currently experiencing some technical difficulties"
					subtitle="An unexpected issue occurred. Our team is on it, please try refreshing the page later."
				/>
				<SimpleFooter showCookieSetting={false} />
				<Scripts />
			</body>
		</html>
	);
}
