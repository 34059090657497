import { loader } from "@monaco-editor/react";
import monacoPackage from "monaco-editor/package.json" assert { type: "json" };

const monacoVersion = monacoPackage.version;
const monacoVsPath = `https://cdn.jsdelivr.net/npm/monaco-editor@${monacoVersion}/min/vs`;

loader.config({
	paths: {
		vs: monacoVsPath,
	},
});

export { monacoVsPath };
